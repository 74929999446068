<template>
  <div class="Drawer">
    <div class="frost" :class="{ 'frosty': shouldOpen }"></div>
    <div class="drawer--contents" v-bind:class="{ open: shouldOpen }">
      <div class="closeDrawer" @click="escapeDrawer">
        <font-awesome-icon class='icon' :icon="['far', 'times-circle']" />
      </div>
      <h2>{{title}}</h2>
      <slot></slot>
      <Button v-if="confirmText" :callback="closeDrawer" :enabled="enableConfirm">{{confirmText}}</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
export default {

  name: 'Drawer',
  components: {
    Button
  },
  props: ['title', 'confirmText', 'enableConfirm', 'shouldOpen', 'closeCallback', 'confirmCallback'],
  data: () => {
    return {
    }
  },
  methods: {
    closeDrawer(force=false) {
      this.$emit('update:shouldOpen', false);
      if(!force && this.confirmCallback && this.enableConfirm) { this.confirmCallback();}
      if(this.closeCallback) { this.closeCallback();}
    },

    escapeDrawer() {
      this.closeDrawer(true);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.frost {
  position: absolute;
  top: 0;
  left: 0;

  background: black;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  opacity: 0;
  transition: opacity linear @animation-duration--normal;
  pointer-events: none;

  &.frosty {
    opacity: .5;
  }
}
.drawer--contents {
  height: 75%;
  width: 100%;
  position: fixed;
  z-index: 1000;
  left: 0;
  padding: 28px;
  bottom: -75%;
  box-sizing: border-box;

  color: @font-color-primary;

  display: flex;
  flex-flow: column;
  align-items: center;
  background: @background-color-secondary;
  transition: bottom linear @animation-duration--normal;
  box-shadow: @box-shadow--inset;

  .closeDrawer {
    position: absolute;
    cursor: pointer;
    left: 15px;
    font-size: 1.5rem;
    top: 15px;
  }

  &.open {
    bottom: 0;
  }
}
</style>
