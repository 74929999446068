<template>
  <Tile z="1" :v-if="canDisplay" class="Counter" :class="{'deleting': isDeleting}">
    <div v-bind:class="{deleteHidden : !canDelete}" class="deleteIcon" @click="deleteCounter(counter.name)">
      <font-awesome-icon :icon="['far', 'times-circle']" />
    </div>
    <div class="content">
      <div class="dataColumn">
        <div class="icon portrait--small z-index--1">
          <font-awesome-icon v-if="counter.icon==='helmet-battle'" :icon="['far', 'helmet-battle']" />
          <i v-if="counter.icon!=='helmet-battle'" :class="counter.icon"></i>
        </div>
        <span class="value font-style--readable">{{value}}</span>
      </div>
      <div class="name--container">
        <div class="name font-size--medium">{{getDisplayText(counter.name)}}</div>
        <span class="commander-text" v-if="getCommanderText(counter.name)">{{getCommanderText(counter.name)}}</span>
      </div>
      <div class="buttons">
        <button class="counter counter--up" @click="changeCounter(counter.name, 1, counter.icon)"><font-awesome-icon :icon="['fas', 'triangle']" /></button>
        <button class="counter counter--down" @click="changeCounter(counter.name, -1, counter.icon)"><font-awesome-icon :icon="['fas', 'triangle']" /></button>
      </div>
    </div>
  </Tile>
</template>

<script>
  import Tile from "@/components/Tile.vue";
  export default {
    name: 'Counter',
    props: ['counter', 'value', 'canDelete', 'canDisplay'],
    components: {
      Tile
    },
    data: () => {
      return {
        isDeleting: false,
      }
    },
    methods: {
      async changeCounter(key, delta, icon) {
        this.$store.commit('change_counter', {counterName:key, counterDelta:delta, counterIcon: icon});
      },

      getDisplayText(counterName) {
        if(counterName === 'Commander_Tax_0') {
          return "Commander Tax";
        } else if(counterName === 'Commander_Tax_1') {
          return "Commander Tax";
        } else {
          return counterName;
        }
      },

      getCommanderText(counterName) {
        if(this.$store.state.player.commanderList) {
          if(counterName === 'Commander_Tax_0') {
            return this.$store.state.player.commanderList[0].name;
          } else if(counterName === 'Commander_Tax_1') {
            return this.$store.state.player.commanderList[1].name;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },

      deleteCounter(counterName) {
        console.log(counterName);
        this.isDeleting = true;
        setTimeout(() => {this.$store.commit('delete_counter', {counterName})}, 1000);
      }
    }
  }
</script>

<style scoped lang="less">
  .Counter {
    display: flex;
    flex-flow: column;
    color: white;
    font-size: 26px;
    margin-left: 0vw;
    
    overflow:  hidden;
    box-sizing: border-box;

    &.deleting {
      height: 0px;
      padding: 0;
      margin-left: 100vw;
      margin-bottom: 0;
    }

    .deleteIcon {
      position: relative;
      height: 0px;
      margin-right: 100%;
      font-size: 16px;
      margin-left: -8px;
      margin-top: -8px;
      cursor: pointer;

      &.deleteHidden {
        opacity: 0;
        pointer-events: none;
      }
    }

    .content {
      display: flex;
      flex-flow: row;
      margin-top: 5px;

      .dataColumn {
        display: flex;
        flex-flow: column;
        .icon {
          background:white;
          color: black;
        }      
        .value {
          margin-top: 5px;
          font-size: .5em;
          font-weight: bold;
        }
      }
    }

    .name--container {
      display: flex;
      flex-flow: column;
      justify-content: center;
      height: 100%;
      margin-left: 10px;
      flex-grow: 1;      

      margin-right: 10px;
      z-index: 2;
      text-transform: capitalize;

      & > * {
        text-shadow: 0px 3px 3px #000;
      }

      .name {
        display: flex;
        align-items: center;
        overflow: hidden;
        height: 50px;
        max-width: 180px;
        white-space: nowrap;
      }

      .commander-text {
        line-height: 15px;
        font-size: .5em;
        
        top: -20px;
        margin-left: 2px;
        text-align: left;
      }
    }


    .buttons {
      display: flex;
      flex-flow: column;

      .counter {
        width: 50px;
        height: 50%;
        border: none;
        background: @app-color-primary;
        color: white;
        outline: none;
        cursor: pointer;
        .corners--rounded();

        &--down {
          margin-top: 3px;

          svg {
            transform: rotate(180deg);
          }
        }

        &:active {
          box-shadow: inset 0px 0px 3px 1px #000000;
          opacity: .8;
        }
      }
    }
  }
</style>
