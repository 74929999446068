<template>
  <div class="Counters">
    <Counter v-for="counter in $store.state.player.counters" :key="counter.name" :counter="counter" :value="getCounterValue(counter.name)" :canDelete="canDeleteCounter(counter)" :canDisplay="shouldDisplayCounter(counter)"></Counter>

    <Button id="openDrawer" :callback="openDrawer">Add Counter</Button>
    <Drawer
      confirmText="Create Counter"
      title="New Counter"
      :enableConfirm="enableCounterCreation()"
      :shouldOpen.sync="shouldOpen"
      :closeCallback="closeDrawerCallback"
      :confirmCallback="addCounter">
      <InputText
        iconClass="coins"
        ref="counterNameInput"
        :inputData="counterName"
        @update="counterName = $event;"
        placeholder="Counter Name"
        maxLength="12"
        clickToDelete=true
        type="text">
      </InputText>
      <div v-if="checkDoubleName">
        You already have a counter named "{{counterName}}""
      </div>
      <div class="counterTable">
        <div v-for="counter in counterIconOptions"
          class="counterIcon"
          @click="setIconName(counter)"
          :class="{ selected: iconName === counter}"
          :key="counter">
          <i class="icon" :class="counter"></i>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
  import Counter from "@/components/Counter.vue";
  import Button from "@/components/Button.vue";
  import Drawer from "@/components/Drawer.vue";
  import InputText from "@/components/InputText.vue";
  export default {
    name: 'Counters',
    components: {
      Counter,
      InputText,
      Button,
      Drawer
    },
    data: () => {
      return {
        iconName:"",
        shouldOpen: false,
        defaultList: [
          "Experience",
          "Poison",
          "Storm",
          "Graveyard",
          "Energy",

          "Artifacts",
          "Creatures",
          "Enchantments",
          "Spells",
          "Lands",
        ],
        counterName: "",
        counterIconOptions: [

        'ss ss-c15', 'ms ms-ability-deathtouch', 'ss ss-tmp', 'ms ms-flashback', 'ms ms-e',

        'ms ms-artifact', 'ms ms-creature', 'ms ms-enchantment', 'ms ms-sorcery', 'ms ms-land']
      }
    },
    computed: {
      checkDoubleName: function() {
        return Object.keys(this.$store.state.player.counters).includes(this.counterName);
        //return Object.keysthis.counterName === 
      }
    },
    methods: {
      getCounterValue(key) {
        if(this.$store.state.player.counters[key]) {
          return this.$store.state.player.counters[key].value;
        } else {
          return 0;
        }
      },

      shouldDisplayCounter(counter) {
        // Edge case to hide commander tax for non-existant partner
        if(counter.name === 'Commander_Tax_1') {
          return this.$store.state.player.commanderList.length > 1;
        } else {
          return true;
        }
      },

      canDeleteCounter(counter) {
        return counter.icon !== 'helmet-battle';
      },

      setCounterName(name) {
        this.counterName = name;
      },

      setIconName(iconClass) {
        this.iconName = iconClass;
        if(!this.counterName || this.defaultList.includes(this.counterName)) {
          let defaultCounterName = '';
          //'ms ms-artifact', 'ms ms-creature', 'ms ms-enchantment', 'ms ms-sorcery', 'ms ms-land', 
          switch(iconClass) {
            case 'ss ss-c15':
              defaultCounterName = 'Experience';
              break;
            case 'ms ms-ability-deathtouch':
              defaultCounterName = 'Poison';
              break;
            case 'ss ss-tmp':
              defaultCounterName = 'Storm';
              break;
            case 'ms ms-flashback':
              defaultCounterName = 'Graveyard';
              break;
            case 'ms ms-e':
              defaultCounterName = 'Energy';
              break;
            case 'ms ms-artifact':
              defaultCounterName = 'Artifacts';
              break;
            case 'ms ms-creature':
              defaultCounterName = 'Creatures';
              break;
            case 'ms ms-enchantment':
              defaultCounterName = 'Enchantments';
              break;
            case 'ms ms-sorcery':
              defaultCounterName = 'Spells';
              break;
            case 'ms ms-land':
              defaultCounterName = 'Lands';
              break;
            default:
              defaultCounterName = ''
          }

          this.counterName = defaultCounterName;
          this.$refs.counterNameInput.setValue(defaultCounterName);
        }
      },

      openDrawer() {
        this.shouldOpen = true;
      },

      closeDrawerCallback() {
        this.counterName = "";
        this.iconName = "";
      },

      addCounter() {
        this.$store.commit('change_counter', {counterName: this.counterName, counterDelta:0, counterIcon: this.iconName, forceCommit:this.counterName});
      },
      enableCounterCreation() {
        if(this.$refs.counterNameInput) {
          // Ensure we have a name and an icon for counters
          return this.$refs.counterNameInput.isValid === 'valid' && this.iconName !== "" && !this.checkDoubleName;
        } else {
          return false;
        }
      }
    }
  }
</script>
<style scoped lang="less">
  .Counters {
    width: 100%;
    overflow-x: hidden;
  }

  .Button {
    margin-top: 0;
  }

  .Drawer {

    & .InputText {
      margin-top: 0;
      margin-bottom: 15px;
    }

    .counterTable {
      display: grid;
      grid-template-columns: 50px 50px 50px 50px 50px;
      grid-gap: 5px;
      justify-content: center;
      justify-items: center;
      margin-top: 15px;

      .counterIcon {
        padding: 10px;
        background: white;
        border-radius: 30px;
        height: 30px;
        width: 30px;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
        cursor: pointer;

        &.selected {
          background: @app-color-primary;
          color: @font-color-primary;
        }

        i {
          font-size: 25px;
        }
      }
    }
  }
</style>