<template>
  <div class="Input InputText">
    <span class="icon icon--decorative">
      <font-awesome-icon :icon="['far', iconClass]" />
    </span>
    <i v-bind:class="{'visible' : isValid === 'invalid'}" class="icon icon--status invalid far fa-exclamation-triangle"></i>
    <i v-bind:class="{'visible' : isValid === 'valid'}" class="icon icon--status valid far fa-check"></i>
    <!-- Need all 3 of those for some weird android bug -->
    <input class="input--text corners--rounded" :type="type"
      v-bind:value="inputData"
      @focus="onFocus"
      v-bind:class="[isValid === 'invalid' ? 'invalid' : 'valid']"
      v-on:input="inputCallBack"
      v-on:blur="() => {touched = true; checkValidity()}"

      @keypress="keypressCallback"
      :placeholder="placeholder"/>

    <label>
      {{placeholder}}
    </label>
    
    <div class="underRow">
      <div v-if="maxLength" class="characterLimit" v-bind:class="{
        'invalid': this.isValid === 'invalid',
      }">
        <span class="reason">{{inValidReason}}</span>
        <span class="lengthCount font-style--readable">{{inputData.length}}/{{maxLength}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: [
    'type',
    'iconClass',
    'placeholder',
    'default',
    'clickToDelete',
    'inputData',
    'maxLength',
    'minLength',
  ],
  created: function() {
    this.checkValidity();
  },
  data: function() {
    return {
      'debounce': '',
      'isValid': '',
      'inValidReason': '',
      'touched': this.default,
    }
  },
  watch: {
    inputData: function() {
      this.checkValidity();
    }
  },
  computed: {
    value: function() {
      return this.inputData;
    }
  },
  methods: {
    inputCallBack: function(e) {
      this.inputData = e.target.value;
      this.$emit('update', this.inputData);
    },
    keypressCallback: function(e) {
      this.touched = true;
      if(e.which === 13) {
        e.target.blur();
      }
    },
    onFocus: function(e) {
      console.log('i am focused');
      console.log(this.clickToDelete);
      if(this.clickToDelete) {
        e.target.value = '';
        this.inputCallBack(e);
        this.setValue('');
      }
    },
    setValue: function(newValue) {
      this.touched = true;
      this.inputData = newValue;
      this.checkValidity();
    },
    checkValidity: function() {
      if(this.debounce !== null) {
        window.clearTimeout(this.debounce);
        this.debounce = null;
      } else {
        this.debounce = setTimeout(this.checkValidity, 0);
        return;
      }

      if(this.touched) {
        if(this.maxLength && this.inputData.length > this.maxLength) {
          this.inValidReason = `Too many characters - max ${this.maxLength}`;
          this.isValid = 'invalid';
        } else if(this.maxLength && this.inputData.length < this.minLength) {
          this.inValidReason = `Too few characters-  min ${this.minLength}`;
          this.isValid = 'invalid';
        } else if(this.inputData.length === 0) {
          this.isValid = '';
          this.inValidReason = '';
        } else {
          this.isValid = 'valid';
          this.inValidReason = '';
        }
      }

      this.$emit('validity', this.isValid === 'valid' ? true : false);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.InputText {

}
</style>
